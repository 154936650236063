@import '../../../assets/variables.scss';

.self-test {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 515px;
  padding: 0 15px;
  box-sizing: border-box;

  .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 500px;
    max-width: 415px;
    padding-bottom: 50px;

    &__inner {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      margin-top: 15px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 2px solid;
      box-sizing: border-box;
      height: 100%;
      min-height: 110px;
      position: relative;
      cursor: pointer;

      span {
        color: $primary-black;
        font-size: 32px;
        line-height: 39px;
        font-weight: 700;
      }

      &_help {
        background-color: var(--primary-color);
        border-color: $primary-color1;
        width: 100%;
        max-width: 415px;
        height: 135px;
        padding-bottom: 20px;
        position: relative;
        overflow: hidden;

        &-wrapper {
          position: relative;
        }

        span {
          color: #fff;
        }
      }

      &_right {
        max-height: 350px;
        width: 100%;
        background-color: #d6d6d6;
        border-color: #d6d6d6;
        overflow: hidden;

        @media (orientation: portrait) {
          min-height: 300px;
        }
      }

      &_left {
        max-height: 350px;
        width: 100%;
        background-color: #9c9c9c;
        border-color: #9c9c9c;
        margin-right: 15px;
        overflow: hidden;

        @media (orientation: portrait) {
          min-height: 300px;
        }
      }

      &_start {
        width: 150px;
        min-height: 40px;
        margin: 20px auto;
      }
    }
  }
}

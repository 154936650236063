.btn {
  height: var(--buttonHeight);
  padding: 0 24px;
  font-size: var(--buttonTextLineSize);
  line-height: var(--buttonTextLineHeight);
  outline: none;
  cursor: pointer;
  font-family: inherit;
  border-radius: 6px;
  transition: 0.25s;
  position: relative;
  white-space: nowrap;
  [data-theme='dark'] & {
    text-transform: uppercase;
  }
  &.medium {
    height: 60px;
  }

  &.btn_submit {
    color: #fff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    &:focus {
      outline: 5px ridge var(--primary-color);
      border: 2px solid white;
    }
    // disable hover on touch devices
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--hover-primary);
      }
    }
  }

  &.outlined {
    background: transparent;
    color: var(--primary-color);
    [aria-selected="true"] & {
      background: var(--primary-color);
    }
    &:hover {
      color: var(--primary-color-light);
      background: var(--primary-color);
    }
  }

  &.btn_default {
    color: $primary-black;
    background: #fff;
    border: 1px solid $primary-black;

    &:focus {
      outline: 5px ridge var(--primary-color);
    }


    // disable hover on touch devices
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $primary-black;
        color: #fff;
      }
    }
  }

  &.btn_decline {
    color: #fff;
    background: $error-color;
    border: 1px solid $error-color;

    // disable hover on touch devices
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #fff;
        border-color: $error-color;
        color: $error-color;
      }
    }
  }

  &.btn_icon {
    padding: 0;
    min-width: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn_reset-icon {
    min-height: 48px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .icon-reset {
      background: url(../assets/button-icons/reset.svg) no-repeat center / cover;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  &:disabled {
    background-color: #a5b4cc;
    border-color: #a5b4cc;
    pointer-events: none;
  }

  &_wide {
    width: 100%;
  }
}

.endTest {
  display: flex;
  align-items: center;
  flex: 1;

  &__textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary-black);
    display: flex;
    align-items: center;
    text-align: center;
    &:focus {
      padding: 7px;
    }
  }
  &__closePage {
    margin-top: 50px;
  }
}

@import '../../../assets/variables.scss';

.results {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1140px;
    padding: 0 15px 30px;
    box-sizing: border-box;
  }
}

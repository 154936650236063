@import '../../../assets/variables.scss';

.radio {
  width: 100%;
  max-width: 170px;

  label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid $primary-black;
    border-radius: 6px;
    color: $primary-black;
    cursor: pointer;
    transition: 0.25s;
    box-sizing: border-box;

    span {
      font-size: 14px;
      line-height: 17px;
    }

    // disable hover on touch devices
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: var(--primary-color);
        color: var(--primary-color);
      }
    }

    &::before {
      transition: 0.25s;
      display: block;
      content: '';
      position: absolute;
      top: 11px;
      left: 12px;
      width: 16px;
      height: 16px;
      background: url(../../../assets/images/icons/radio.svg) no-repeat center / contain;
      stop-color: black;
    }
  }

  input {
    display: none;
  }

  &.checked {
    label {
      color: var(--primary-color);
      border-color: var(--primary-color);

      &::before {
        background: url(../../../assets/images/icons/radio-checked.svg) no-repeat center / contain;
      }
    }
  }

  // styles for radio button with icon
  &.icon {
    max-width: 160px;

    label {
      height: auto;
      flex-direction: column;
      border-color: $primary-grey1;

      &::before {
        left: auto;
        right: 12px;
      }

      span {
        padding: 11px 11px 11px 14px;
        border-bottom: 1px solid $primary-grey1;
        width: 100%;
        box-sizing: border-box;
        transition: 0.25s;
        border-radius: 6px 6px 0 0;
      }
    }

    .icon {
      width: 67px;
      height: 80px;

      &.icon-patient {
        background: url(../../../assets/images/icons/patient.svg) no-repeat center / cover;
      }

      &.icon-doctor {
        background: url(../../../assets/images/icons/doctor.svg) no-repeat center / cover;
      }

      &.icon-smartphone {
        background: url(../../../assets/images/icons/smartphone.svg) no-repeat center / cover;
      }

      &.icon-laptop {
        background: url(../../../assets/images/icons/laptop.svg) no-repeat center / cover;
      }

      &.icon-infant {
        height: 60px;
        background: url(../../../assets/images/icons/infant.svg) no-repeat center / cover;
      }

      &.icon-child {
        height: 60px;
        background: url(../../../assets/images/icons/child.svg) no-repeat center / cover;
      }

      &.icon-adult {
        height: 60px;
        background: url(../../../assets/images/icons/adult.svg) no-repeat center / cover;
      }

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 84px;
        padding: 11px 0;
        box-sizing: border-box;
      }
    }

    // reversed radio icon
    &_reversed {
      label {
        flex-direction: column-reverse;
        height: 100%;

        .icon-container {
          min-height: 102px;
        }

        span {
          border-bottom: none;
          border-top: 1px solid $primary-grey1;
          border-radius: 0 0 6px 6px;
          text-align: center;
          padding: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }

    // disable hover on touch devices
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        label {
          color: var(--primary-color);
          border-color: var(--primary-color);
          box-shadow: 0 0 6px rgba(66, 101, 207, 0.25);
        }

        span {
          border-color: var(--primary-color);
          background-color: var(--primary-color-light);
        }
      }
    }

    &.checked {
      label {
        color: var(--primary-color);
        border-color: var(--primary-color);
        box-shadow: 0 0 6px rgba(66, 101, 207, 0.25);
      }

      span {
        border-color: var(--primary-color);
        background-color: var(--primary-color-light);
      }
    }

    &.disabled {
      opacity: 0.8;
      pointer-events: none;
      background-color: hsl(0, 0%, 95%);
    }
  }
}

@import '../../../../assets/variables.scss';

.distance-form {
  width: 100%;
  min-width: 345px;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  flex: 1 1 20em;
  padding-bottom: 16px;
  margin-left: 15px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 36px;
    max-width: 345px;
  }

  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $primary-grey1;
    border-radius: 6px;
    box-sizing: border-box;
    padding-right: 50px;
    padding-left: 14px;
    margin-top: 20px;
  }

  &__btns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;

    .radio:last-child {
      margin-left: 15px;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 20px;
    color: $primary-black;
    position: relative;
  }

  &__input-text {
    position: absolute;
    right: 14px;
    bottom: 11px;
    font-size: 14px;
    line-height: 17px;
    color: $primary-black;
  }

  input {
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

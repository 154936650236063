@import '../../../assets/variables.scss';

.doctor-home {
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;

  &__inner {
    width: 100%;
    max-width: 540px;
    box-sizing: border-box;
    flex: 1 1 345px;
    margin-left: 15px;
    margin-bottom: 24px;

    @media (max-width: 750px) {
      max-width: none;
    }
  }
}

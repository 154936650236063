@import '../../../assets/variables.scss';

.patient-test {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100% - 70px);
  padding: 0;
  margin: 0;

  &.rotate {
    transform: rotate(-90deg);
    height: calc(100vw - 70px);
    transform-origin: left top;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@import '../../../assets/variables.scss';

.header-menu {
  position: absolute;
  top: 20px;
  right: 30px;

  @media (max-width: 1100px) {
    right: 15px;
  }

  &__inner {
    position: relative;
  }

  &__dropdown {
    position: absolute;
    top: 55px;
    right: 0;
    width: 160px;
    border: 1px solid $primary-grey1;
    border-radius: 4px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 7px 0;
    background-color: #fff;
    z-index: 1;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;

    &:hover {
      opacity: 1;
      visibility: visible;
    }

    &-item {
      background-color: #fff;
      color: $primary-grey;
      font-size: 12px;
      line-height: 15px;
      padding: 8px 15px;
      cursor: pointer;

      // disable hover on touch devices
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: #eff3f8;
          color: #151515;
        }
      }
    }
  }

  &__avatar {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    background-color: #c867d8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      color: #fff;
      font-size: 25px;
      line-height: 25px;
      text-transform: uppercase;
      font-family: Arial, Helvetica, sans-serif;
    }

    &:hover {
      & + .header-menu__dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

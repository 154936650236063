@import '../../../assets/variables.scss';

.connect {
  max-width: 570px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px 70px;

  &__inner {
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
    padding: 30px;
    border: 1px solid $primary-grey1;
    box-sizing: border-box;
  }

  &__input {
    height: 40px;
    width: 100%;
    border: 1px solid $primary-grey1;
    border-radius: 6px;
    background-color: #f8faff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: $primary-black;
    box-sizing: border-box;
    padding-left: 14px;
    user-select: text;
    margin-bottom: 24px;
    outline: none;

    &:disabled {
      letter-spacing: 0.3em;
    }

    &::placeholder {
      letter-spacing: normal;
      font-size: 14px;
      color: $primary-grey2;
    }

    &-wrapper {
      position: relative;
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
  }

  &__copy-btn {
    height: 40px;
    width: 40px;
    background-image: url(../../../assets/button-icons/copy.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: $primary-black;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    color: $primary-black;
    margin: 0;
    margin-bottom: 16px;

    &_warning {
      visibility: visible;
      color: #fe565e;
      width: 100%;
      max-width: 490px;
    }
  }
}

.ultraLight18x27 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 200;
}

.light18x27 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}

.bold18x35 {
  font-size: 18px;
  line-height: 35px;
  font-weight: 700;
}

.bold24x35 {
  font-size: 24px;
  line-height: 35px;
  font-weight: 700;
}

.bold32x35 {
  font-size: 24px;
  line-height: 35px;
  font-weight: 700;
}

.black24x35 {
  font-size: 24px;
  line-height: 35px;
  font-weight: 800;
}

.warning {
  width: 100%;
  height: 100%;
  background-color: rgba(80, 80, 80, 0.8);
  position: absolute;
  text-align: center;
  z-index: 2;

  &.hidden {
    display: none;
  }

  &__container {
    padding: 10px;
    background-color: #a7a7a7;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  a {
    color: black;
  }
}

@import '../../../../assets/variables.scss';
@import '../../../../assets/typography.scss';

.eyeDark {
  width: 100%;
  height: 100%;
  background-color: var(--background-color1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  &__heading {
    margin-top: 30px;
    text-align: center;
    color: var(--primary-black);
    @media (min-width: 600px) {
      width: 45%;
    }
  }

  &__content {
    width: 100%;
    text-align: center;
    color: var(--primary-black);
    @media (min-width: 600px) {
      width: 55%;
    }

    @media (max-width: 400px) {
      width: 85%;
    }
    &-block {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__distanceResultContainer {
    position: relative;
    width: 1040px;
    height: 449px;
    border: 1px solid var(--background-color2);
    border-radius: 10px;
    background-color: var(--white);
    margin-top: 30px;
    display: flex;
    align-items: center;
    @media (max-width: 1100px) {
      width: 900px;
      height: 449px;
    }
    @media (max-width: 900px) {
      width: 700px;
      height: 400px;
    }
    @media (max-width: 700px) {
      width: 500px;
      height: 400px;
    }
    @media (max-width: 500px) {
      width: 300px;
      height: 400px;
    }
  }

  .btn {
    width: 100%;
    height: 40px;
    margin-top: 60px;
    display: none;
    &:disabled {
      background-color: var(--white);
    }

    @media (max-width: 500px) {
      display: block;
    }
  }

  &__takeAway {
    position: absolute;
    top: 25%;
    right: 25%;
    display: block;

    @media (max-width: 1100px) {
      top: 20%;
      right: 20%;
      max-width: 40%;
      text-align: center;
    }

    @media (max-width: 700px) {
      right: 5%;
    }

    @media (max-width: 500px) {
      display: none;
    }

    span {
      color: var(--primary-black);
      text-transform: uppercase;
    }
  }

  h3 {
    padding: 0;
    font-size: 30px;
  }

  &.self {
    background-color: var(--background-color);
    justify-content: flex-start;

    h3 {
      font-size: 20px;
      margin: 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    align-items: center;
    max-height: 1200px;
    padding: 0 30px;
    &.left {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }

  .distance-result__inner {
    scale: 1.4;
    margin-left: 220px;
  }
  .icon-ruler {
    width: 500px;
  }
  .icon-footsteps {
    width: 500px;
  }
  .distance-result__content-right {
    align-items: flex-start;
  }

  @media (max-width: 1100px) {
    .distance-result__inner {
      scale: 1.3;
      margin-left: 170px;
    }
    .icon-ruler {
      width: 80%;
    }
    .icon-footsteps {
      width: 500px;
    }
  }

  @media (max-width: 900px) {
    .distance-result__inner {
      scale: 1.3;
      margin-left: 100px;
    }
    .icon-ruler {
      width: 80%;
    }
    .icon-footsteps {
      width: 300px;
    }
  }

  @media (max-width: 700px) {
    .distance-result__inner {
      scale: 1.2;
      margin-left: 80px;
    }
    .icon-ruler {
      width: 80%;
    }
    .icon-footsteps {
      width: 200px;
    }
  }

  @media (max-width: 500px) {
    .distance-result__inner {
      scale: 1;
      margin-left: 20px;
    }
    .icon-ruler {
      width: 70%;
    }
    .icon-footsteps {
      width: 110px;
    }
  }

  &__text {
    color: var(--primary-black);
    text-align: center;
    &:focus {
      padding: 7px;
    }
  }
}

@import '../../../assets/variables.scss';

.test-history-result {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-right: 12px;
  padding-bottom: 8px;
  box-sizing: border-box;
  background: var(--primary-color);
  border: 1px solid $primary-color1;
  border-radius: 6px;
  margin-top: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 8px;

    &-inner {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-right: 10px;

      &:first-child {
        flex: 1 1 220px;
      }
    }

    span {
      color: #fff;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 8px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  &__date {
    margin-bottom: 8px;

    span {
      color: #ccd9ff;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    &-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      flex: 1 1 450px;
    }
  }

  &__input {
    flex: 4 1 300px;
    margin-bottom: 8px;
    margin-right: 8px;

    &_small {
      flex: 1 1 140px;
    }
  }
}

$background-color: #fafafc;
$background-color1: #9c9c9c;
$primary-color: #4265cf;
$primary-color1: #122f86;
$primary-color-light: #ecf1ff;
$primary-black: #252b30;
$primary-grey: #585a67;
$primary-grey1: #d3dce9;
$primary-grey2: #8d9db5;
$success-color: #128632;
$success-color1: #e2ffea;
$error-color: #e74025;
$accent-color: #9bff74;
$warning-color: #ee983e;

.qr-notification {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 68px;
    height: 68px;
    margin-top: 36px;

    &.icon-success {
      background-image: url(../../../assets/images/icons/qr-success.svg);
    }

    &.icon-error {
      background-image: url(../../../assets/images/icons/qr-error.svg);
    }
  }

  .page-description {
    margin-top: 12px;
  }
}

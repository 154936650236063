@import '../../../assets/variables.scss';

.calibration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 640px;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;

  .radio-buttons {
    display: flex;
    justify-content: space-between;
    max-width: 355px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 28px;

    .radio:last-child {
      margin-left: 15px;
    }
  }

  &__buttons {
    max-width: 610px;
    width: 100%;
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn_reset-icon {
      min-width: 110px;
      margin: 0 8px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      width: 102px;
      min-width: 102px;
    }

    @media (max-width: 1100px) {
      margin-bottom: 27px;
    }
  }
}

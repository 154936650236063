@import '../../../assets/variables.scss';

.dyops {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .rotating {
      -webkit-animation: rotating 1.5s linear infinite;
      -moz-animation: rotating 1.5s linear infinite;
      -ms-animation: rotating 1.5s linear infinite;
      -o-animation: rotating 1.5s linear infinite;
      animation: rotating 1.5s linear infinite;
    }

    .rotating-counter-clockwise {
      -webkit-animation: rotating-counter-clockwise 1.5s linear infinite;
      -moz-animation: rotating-counter-clockwise 1.5s linear infinite;
      -ms-animation: rotating-counter-clockwise 1.5s linear infinite;
      -o-animation: rotating-counter-clockwise 1.5s linear infinite;
      animation: rotating-counter-clockwise 1.5s linear infinite;
    }
  }

  &__item {
    background-color: #d6d6d6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__image {
    position: absolute;

    &-wrapper {
      position: relative;
    }
  }

  &-indicator {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      height: 65px;

      @media (max-width: 1100px) {
        height: 60px;
      }

      .icon-direction {
        width: 30px;
        height: 30px;
        background-color: #fff;
        mask: url(../../../assets/button-icons/direction.svg) no-repeat center / contain;
        -webkit-mask: url(../../../assets/button-icons/direction.svg) no-repeat center / contain;

        &.clockwise {
          -webkit-animation: steer 2s linear infinite;
          -moz-animation: steer 2s linear infinite;
          animation: steer 2s linear infinite;
        }

        &.counterclockwise {
          mask: url(../../../assets/button-icons/direction-counterclowise.svg) no-repeat center / contain;
          -webkit-mask: url(../../../assets/button-icons/direction-counterclowise.svg) no-repeat center / contain;
          -webkit-animation: steer-counter-clockwise 2s linear infinite;
          -moz-animation: steer-counter-clockwise 2s linear infinite;
          animation: steer-counter-clockwise 2s linear infinite;
        }
      }
    }

    &__text {
      font-size: 24px;
      line-height: 30px;
      text-transform: uppercase;
      color: #ff3413;

      @media (max-width: 1100px) {
        font-size: 20px;
        line-height: 24px;
      }

      &.active {
        color: $accent-color;
      }
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating-counter-clockwise /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotating-counter-clockwise {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

// arrow rotating for doctor test page
@-webkit-keyframes steer {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes steer {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes steer-counter-clockwise {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes steer-counter-clockwise {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

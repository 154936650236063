@import '../../../assets/variables.scss';

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  background-color: var(--background-color3);
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.05);
  height: 70px;
  min-height: 70px;
  box-sizing: border-box;

  &__inner {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: flex-end;
  }

  &_single-item {
    justify-content: flex-end;
  }

  @media (max-width: 780px) {
    padding: 0 15px;
  }

  &.rotate {
    transform: rotate(-90deg);
    transform-origin: left top;
    position: absolute;
    z-index: 1;
    position: fixed;
    top: 100%;
    left: calc(100vw - 70px);
    width: 100vh;
  }
}

@import './variables.scss';
@import './form.scss';
@import './icons.scss';
@import './buttons.scss';

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr-Medium'), url(../fonts/HelveticaNeueCyr-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr-Bold'), url(../fonts/HelveticaNeueCyr-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr-Black'), url(../fonts/HelveticaNeueCyr-Black.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr-Light'), url(../fonts/HelveticaNeueCyr-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: local('HelveticaNeueCyr-UltraLight'), url(../fonts/HelveticaNeueCyr-UltraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Bold'), url(../fonts/NotoSans-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Light'), url(../fonts/NotoSans-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-ExtraLight'), url(../fonts/NotoSans-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans-Medium'), url(../fonts/NotoSans-Medium.ttf) format('truetype');
  font-weight: 500;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body > iframe[style*='2147483647'] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
div,
span,
a,
p,
ul,
li,
th,
button,
input,
label,
textarea,
input::placeholder,
textarea::placeholder {
  -webkit-tap-highlight-color: transparent;
  font-family: 'HelveticaNeueCyr';
  [data-theme='dark'] & {
    font-family: 'NotoSans';
  }
}

.highlight-text {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: default;
}

.page-heading {
  padding: 2px;
  font-size: 24px;
  line-height: 30px;
  color: $primary-black;
  margin: 0;
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;

  &.small {
    font-size: 20px;
    line-height: 24px;
  }

  &.light {
    margin-bottom: 4px;
    font-weight: 300;
  }

  &.ultraLight {
    font-weight: 200;
    margin-bottom: 4px;
  }
}

.page-description {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;

  &.light {
    font-weight: 300;
  }
}

#root {
  height: 100%;
}

:root {
  --white: #fff;
  --black: #000;
  --background-color: #fafafc;
  --background-color1: #9c9c9c;
  --background-color2: #c6d2f3;
  --background-color3: #fff;
  --primary-color: #4265cf;
  --primary-color1: #122f86;
  --primary-color-light: #ecf1ff;
  --primary-black: #252b30;
  --primary-grey: #585a67;
  --primary-grey1: #d3dce9;
  --primary-grey2: #8d9db5;
  --success-color: #128632;
  --success-color1: #e2ffea;
  --error-color: #e74025;
  --accent-color: #9bff74;
  --warning-color: #ee983e;
  --hover-primary: #2a49a7;
  --logo: url(./images/icons/dyop-logo.svg) no-repeat center / cover;
  --buttonHeight: 40px;
  --buttonTextLineHeight: 17px;
  --buttonTextSize: 14px;
  html,
  * {
    font-family: 'HelveticaNeueCyr';
  }
}

[data-theme='dark'] {
  --white: #fff;
  --black: #000;
  --background-color: #ffff;
  --background-color1: #fff;
  --background-color2: #c6d2f3;
  --background-color3: #fafafc;
  --primary-color: #252b30;
  --primary-color1: #122f86;
  --primary-color-light: #ecf1ff;
  --primary-black: #252b30;
  --primary-grey: #585a67;
  --primary-grey1: #d3dce9;
  --primary-grey2: #8d9db5;
  --success-color: #128632;
  --success-color1: #e2ffea;
  --error-color: #e74025;
  --accent-color: #9bff74;
  --warning-color: #ee983e;
  --hover-primary: #585a67;
  --logo: url(./images/icons/dyop-logo-dark.svg) no-repeat center / cover;
  --buttonHeight: 60px;
  --buttonTextLineHeight: 24px;
  --buttonTextSize: 35px;
  html,
  * {
    font-family: 'NotoSans';
  }
}

@import '../../../assets/variables.scss';

.content_test {
  .content__inner {
    position: relative;
    width: 100%;
    background-color: #fff;
    justify-content: flex-end;
    height: calc(100% - 70px);
    padding: 0;
    margin: 0;
    overflow: hidden;

    @media (orientation: portrait) {
      height: 100%;
    }
  }

  &.self {
    background-color: $background-color;

    .content__inner {
      background-color: $background-color;

      .patient-test {
        max-width: 485px;
      }
    }
  }

  &.test {
    .content__inner {
      background-color: var(--background-color1);
    }
  }
}

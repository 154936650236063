.icon-tuning {
  height: 24px;
  width: 24px;
  background-color: #fff;
  mask-repeat: no-repeat, no-repeat;
  mask-position: center, center;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat, no-repeat;
  -webkit-mask-position: center, center;
  -webkit-mask-size: contain;

  &.icon-minus {
    mask-image: url(../assets/images/icons/minus.svg);
    -webkit-mask-image: url(../assets/images/icons/minus.svg);
    mask-size: cover;
    -webkit-mask-size: cover;
    max-height: 4px;

    &.small {
      width: 15px;
      height: 3px;
    }
  }

  &.icon-minus-5 {
    background-color: transparent;
    width: 12px;
    font-size: 24px;
    line-height: 24px;
    margin-left: 3px;
  }

  &.icon-plus {
    mask-image: url(../assets/images/icons/minus.svg), url(../assets/images/icons/plus.svg);
    -webkit-mask-image: url(../assets/images/icons/minus.svg), url(../assets/images/icons/plus.svg);

    &.small {
      width: 15px;
      height: 15px;
    }
  }

  &.icon-plus-5 {
    background-color: transparent;
    width: 12px;
    font-size: 24px;
    line-height: 24px;
    margin-left: 3px;
  }
}

.welcome {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;

  &__logoContainer {
    cursor: pointer;
    border: 0;
    background-color: white;
  }

  &__textContainer {
    display: flex;
    max-width: 60%;
    flex-direction: column;
    align-items: center;
    span {
      &:first-child {
        margin-top: 91px;
      }
      text-align: center;
    }
    @media (max-width: 600px) {
      max-width: 80%;
    }
  }
}

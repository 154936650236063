@import '../../../assets/variables.scss';

.doctor-test {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $background-color1;
  height: calc(100% - 50px);
  padding: 0;
  margin: 0;

  .dyops__start-btn {
    margin: 0 auto;
    max-width: 186px;
    min-height: 40px;

    @media (max-height: 400px) {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }

  &.rotate {
    transform: rotate(-90deg);
    height: calc(100vw - 70px);
    transform-origin: left top;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;

    .dyops__start-btn {
      @media (max-width: 400px) {
        position: absolute;
        top: 30px;
        right: 30px;
      }
    }
  }

  &__dyops {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dyops {
      &__container {
        height: auto;
      }
    }
  }

  .dyops__control-btns {
    margin: 20px 0;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 92px;
        cursor: pointer;

        span {
          text-transform: uppercase;
          color: #fff;
          margin-top: 9px;
          font-size: 12px;
          line-height: 14px;
        }

        // enable hover on non-touch devices
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            .icon {
              background-color: #fff;
            }
          }
        }

        // enable hover on non-touch devices
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            .icon__wrapper {
              background-color: var(--primary-color);
              border-color: var(--primary-color);

              .icon {
                background-color: #fff;
              }

              & + span {
                color: var(--primary-color);
              }
            }
          }
        }
      }

      .icon {
        cursor: pointer;
        min-width: 52px;
        min-height: 52px;
        background-color: $primary-grey;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;
        mask-size: cover;
        -webkit-mask-size: cover;

        &__wrapper {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #d6d6d6;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
        }

        &-play {
          mask-image: url(../../../assets/button-icons/play.svg);
          -webkit-mask-image: url(../../../assets/button-icons/play.svg);
        }

        &-pause {
          mask-image: url(../../../assets/button-icons/pause.svg);
          -webkit-mask-image: url(../../../assets/button-icons/pause.svg);
        }

        &-zoom-in {
          mask-image: url(../../../assets/button-icons/incorrect.svg);
          -webkit-mask-image: url(../../../assets/button-icons/incorrect.svg);
        }

        &-zoom-out {
          mask-image: url(../../../assets/button-icons/correct.svg);
          -webkit-mask-image: url(../../../assets/button-icons/correct.svg);
        }

        &-help {
          mask-image: url(../../../assets/button-icons/question.svg);
          -webkit-mask-image: url(../../../assets/button-icons/question.svg);
        }

        &-rotation {
          mask-image: url(../../../assets/button-icons/rotation.svg);
          -webkit-mask-image: url(../../../assets/button-icons/rotation.svg);
        }

        &-next {
          mask-image: url(../../../assets/button-icons/right-arrow.svg);
          -webkit-mask-image: url(../../../assets/button-icons/right-arrow.svg);
        }

        &-prev {
          mask-image: url(../../../assets/button-icons/right-arrow.svg);
          -webkit-mask-image: url(../../../assets/button-icons/right-arrow.svg);
          transform: rotate(180deg);
        }

        &-restart {
          mask-image: url(../../../assets/button-icons/rotation.svg);
          -webkit-mask-image: url(../../../assets/button-icons/rotation.svg);
        }
      }
    }
  }
}

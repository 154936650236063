@import '../../../assets/variables.scss';

.qr-scan {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  padding: 0 15px;
  box-sizing: border-box;
  padding-bottom: 70px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 15px;
    border: 1px solid $primary-grey1;
    border-radius: 6px;
    background-color: #fff;
  }

  &__heading {
    padding: 0 15px;
    text-align: center;
    padding-bottom: 24px;
  }

  &__scanner {
    width: 100%;
    border-radius: 4px;
    height: 72vh;
    max-height: 580px;

    section {
      height: 72vh;
      max-height: 580px;
      padding-top: 0 !important;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      // scan block style
      div {
        width: 30vh !important;
        height: 30vh !important;
        min-height: 200px !important;
        min-width: 200px !important;
        max-width: 256.5px !important;
        max-height: 256.5px !important;
        position: relative !important;
        box-shadow: rgba(0, 0, 0, 0.3) 26px 1px 0 192px !important;
        border: none !important;

        // scan block borders style
        &::before {
          display: block;
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          background:
            linear-gradient(to right, var(--primary-color) 3px, transparent 3px) 0 0,
            linear-gradient(to right, var(--primary-color) 3px, transparent 3px) 0 100%,
            linear-gradient(to left, var(--primary-color) 3px, transparent 3px) 100% 0,
            linear-gradient(to left, var(--primary-color) 3px, transparent 3px) 100% 100%,
            linear-gradient(to bottom, var(--primary-color) 3px, transparent 3px) 0 0,
            linear-gradient(to bottom, var(--primary-color) 3px, transparent 3px) 100% 0,
            linear-gradient(to top, var(--primary-color) 3px, transparent 3px) 0 100%,
            linear-gradient(to top, var(--primary-color) 3px, transparent 3px) 100% 100%;
          background-repeat: no-repeat;
          background-size: 40px 40px;
        }
      }
    }
  }
}

@import '../../../assets/variables.scss';

.content {
  background-color: var(--background);
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__heading-container {
    max-width: 570px;
    padding: 0 15px;
    margin-bottom: 48px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 48px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.small-content {
    .content__heading-container {
      margin-bottom: 10px;
    }

    .content__inner {
      padding-top: 10px;
      margin-top: 4%;
    }
  }

  &.test {
    .content__inner {
      background-color: $background-color1;
    }
  }
}

@import '../../../assets/variables.scss';

.navigation {
  min-width: 345px;
  height: 82px;
  width: 100%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 720px) {
    max-width: none;
    padding: 0 15px;
    justify-content: center;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 495px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 87%;
      position: absolute;
      top: 13px;
      left: 20px;
      height: 2px;
      background-color: $primary-grey1;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    &:last-child {
      position: relative;

      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 58px;
        width: 30px;
        height: 30px;
        background-color: #fff;
      }
    }

    &-icon {
      background: #fff;
      border: 2px solid $primary-grey1;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      span {
        font-size: 14px;
        line-height: 17px;
        color: $primary-black;
      }

      &.done {
        border: none;
        content: '';
        background: url(../../../assets/images/icons/navigation-checked.svg) no-repeat center / cover;

        span {
          display: none;
        }
      }

      &.active {
        background-color: var(--primary-color);
        border-color: var(--primary-color);

        span {
          color: #fff;
        }
      }
    }
  }

  &__description {
    color: $primary-black;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
  }
}

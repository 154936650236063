.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  padding: 0 7.5px 88px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &__heading {
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
  }

  &__column {
    display: flex;
    width: 100%;
    margin: 0 7.5px 16px;

    &_small {
      max-width: 176px;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    // 3 inputs in 1 row styles
    &.w-50 {
      flex-wrap: wrap;
      justify-content: space-between;

      .form__column {
        flex: 1 1 240px;

        .input-container {
          min-width: 120px;

          &:nth-child(2) {
            margin-left: 15px;
          }
        }
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    flex-wrap: wrap;
    padding: 0 7.5px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: 442px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &-item {
      display: flex;
      flex-wrap: nowrap;
      max-width: 400px;
      min-width: 237px;
      margin-right: 15px;
    }

    span {
      color: $primary-grey;
    }

    a {
      margin-left: 5px;
      color: var(--primary-color);
      outline: none;
    }

    button {
      max-width: 160px;

      @media (max-width: 442px) {
        max-width: none;
        margin-bottom: 20px;
      }
    }

    &_login {
      width: 100%;

      .form__btns-item {
        min-width: auto;
        max-width: none;
        width: 100%;
        justify-content: space-between;
        margin-right: 0;
        margin-top: 20px;

        @media (max-width: 442px) {
          flex-direction: column-reverse;
          margin-top: 0;
        }
      }
    }
  }

  &__radio {
    &-wrapper {
      width: 100%;
      max-width: 375px;
      padding: 0 7.5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 36px;
    }

    &-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }

  &__forgot-link {
    position: absolute;
    top: -6px;
    right: 7.5px;
    color: var(--primary-color);
    font-size: 12px;
    line-height: 20px;
  }
}

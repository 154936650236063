@import '../../../assets/variables.scss';

.result-container {
  width: 100%;
  background-color: #fff;
  border: 1px solid $primary-grey1;
  padding: 30px 0 6px 30px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 28px;

  @media (max-width: 768px) {
    padding: 30px 0 0 10px;
    text-align: center;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    max-width: 100%;
    width: 100%;
    flex: 1 1 320px;
    margin-right: 30px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }

  &__heading {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-right: 30px;
    box-sizing: border-box;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      padding-right: 10px;
    }

    span:first-child {
      margin-right: 20px;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-top: 15px;
      }
    }
  }
}

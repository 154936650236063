.distanceNew {
  margin-top: 61px;
  &__buttonContainer {
    display: flex;
    flex-direction: column;

    & button {
      width: 420px;
      &:nth-child(n + 2) {
        margin-top: 40px;
      }
      &.selected {
        background-color: var(--primary-color);
        color: var(--white);
      }
      font-size: 18px;
      font-weight: 700;
      @media (max-width: 450px) {
        font-size: 12px;
        width: 290px;
      }
    }
  }
  .input-container {
    margin-top: 40px;
    justify-content: center;
    .select__control {
      height: 60px;
    }
  }

  .page-description {
    font-size: 18px;
    font-weight: 200;
    line-height: 27px;
  }
}

@import '../../../assets/variables.scss';

.calibration {
  &-ruler {
    width: 100%;
    height: 280px;
    background-image: url(../../../assets/images/card_x.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    [data-theme='dark'] & {
      background-image: url(../../../assets/images/card-dark_x.svg);
    }

    @media (max-width: 1100px) {
      background-image: url(../../../assets/images/card_y.svg);
      height: 330px;
      [data-theme='dark'] & {
        background-image: url(../../../assets/images/card-dark_y.svg);
      }
    }

    &__units {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-color);
      color: white;
      font-size: 12px;
      width: 60px;
      height: 20px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin: 0 auto;
    }

    &__wrapper {
      position: relative;
      border-left: 2px dashed #303779;
      border-right: 2px dashed #303779;
    }
  }

  &__arrows-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 30px;
    z-index: 2;
    position: relative;

    .arrow {
      height: 2px;
      margin-top: 20px;
      background-color: #000;
      position: absolute;
      left: 0;

      &::before {
        position: absolute;
        top: -4px;
        left: 0;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #000;
      }

      &.reverse {
        right: 0;

        &::before {
          right: 0;
          left: auto;
          transform: rotate(180deg);
        }
      }
    }
  }
}

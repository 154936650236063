@import '../../../assets/variables.scss';

.patients {
  align-items: center;
  max-height: 352px;
  min-height: 286px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid $primary-grey1;
  padding-top: 20px;
  box-sizing: border-box;
  background: #fff;

  &__header {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  &__list {
    padding-top: 16px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 5px;
    height: 240px;
    margin-top: 2px;

    &.disabled {
      opacity: 0.7;
    }

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background: #eff4fa;
      visibility: hidden;
      border-radius: 0 0 6px 0;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #a2b5cf;
      visibility: hidden;
    }

    // make scroll visible when needed
    &.scroll {
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }

    &-item {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 12px;
      border-bottom: 1px solid #eff3f8;
      box-sizing: border-box;
      cursor: pointer;

      span {
        color: $primary-grey;
        font-size: 16px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      // disable hover on touch devices
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: #eff3f8;
          color: $primary-black;
          border-color: $primary-grey1;
        }
      }

      &.active {
        background: #eff3f8;
        color: $primary-black;
        border-color: $primary-grey1;
      }
    }
  }

  &__loading {
    display: block;
    margin-top: 24px;
    margin-left: 20px;
  }
}

@import '../../../assets/variables.scss';

.eye {
  width: 100%;
  height: 100%;
  background-color: $background-color1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  h3 {
    padding: 0;
    font-size: 30px;
  }

  // self test eye prepare styles
  &.self {
    background-color: $background-color;
    justify-content: center;

    h3 {
      font-size: 20px;
      margin: 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 1200px;
  }

  &__text {
    max-width: 200px;
  }
}

@import '../../../../assets/variables.scss';

.headerDark {
  width: 100%;
  background: var(--background-color3);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 25px;
  position: relative;
  box-sizing: border-box;

  .status-wrapper {
    &__indicator {
      height: 20px;
      width: 20px;
    }
  }

  &__logo {
    width: 100%;
    max-width: 260px;
    height: 87px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 720px) {
      padding: 0 15px;
      border-bottom: 1px solid $primary-grey1;
      max-width: none;
    }

    span {
      margin: 0;
    }
  }

  &__icon {
    height: 68px;
    width: 68px;
    background: var(--logo);
    margin-right: 10px;
  }
}

@import '../../../assets/variables.scss';

.qr-connect {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 475px;
  padding: 0 15px;
  box-sizing: border-box;
  padding-bottom: 70px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 65px;
    border: 1px solid $primary-grey1;
    border-radius: 6px;

    // qr code style
    .svg-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      margin-top: 32px;

      &::before,
      &::after,
      &__inner::before,
      &__inner::after {
        position: absolute;
        content: ' ';
        width: 40px;
        height: 40px;
        border-color: $primary-black;
        border-style: solid;
      }

      &::before {
        top: 0;
        left: 0;
        border-width: 1px 0 0 1px;
      }

      &::after {
        top: 0;
        right: 0;
        border-width: 1px 1px 0 0;
      }

      &__inner::before {
        bottom: 0;
        right: 0;
        border-width: 0 1px 1px 0;
      }

      &__inner::after {
        bottom: 0;
        left: 0;
        border-width: 0 0 1px 1px;
      }
    }
  }
}

@import '../../../assets/variables.scss';

.empty-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 220px;
  margin: 48px auto 0;

  .icon-warning {
    width: 30px;
    height: 30px;
    background: url(../../../assets/images/icons/warning.svg) no-repeat center / cover;
  }

  span {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: $primary-grey;
    margin-top: 12px;
  }
}

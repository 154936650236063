@import '../../../assets/variables.scss';

.test-history {
  padding: 20px 0 0 20px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid $primary-grey1;
  max-height: 428px;
  min-height: 286px;
  box-sizing: border-box;
  max-width: 1110px;
  width: 100%;

  &__header {
    padding-right: 20px;
  }

  &__items {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
    max-height: 300px;
    margin-top: 2px;

    &.disabled {
      opacity: 0.7;
    }

    .test-history-result:last-child {
      margin-bottom: 20px;
    }

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background: #eff4fa;
      visibility: hidden;
      border-radius: 0 0 6px 0;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #a2b5cf;
      visibility: hidden;
    }

    &.scroll {
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}

@import '../../../assets/variables.scss';

.input-container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  .label {
    display: flex;
    flex-direction: column;

    &__name {
      align-items: center;
      background: $primary-grey1;
      border-radius: 4px 4px 0 0;
      padding: 2px 12px 0;
      font-size: 12px;
      line-height: 20px;
      box-sizing: border-box;
      margin-left: 8px;
      width: fit-content;
      color: #fff;

      &.disabled {
        background-color: var(--primary-color);
      }
    }

    &:focus-within {
      span {
        background-color: var(--primary-color);
      }

      .input {
        border-color: var(--primary-color);
      }
    }
  }

  .input {
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    height: 40px;
    width: 100%;
    color: $primary-black;
    padding: 0 14px;
    font-size: 14px;
    box-sizing: border-box;
    background-color: var(--primary-color-light);
    outline: none;

    &::placeholder {
      color: $primary-grey2;
      font-weight: 300;
    }

    &.disabled {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:not(.disabled) {
      background-color: #fff;
      border-color: $primary-grey1;
      padding-right: 33px;
      // font-family: 'HelveticaNeueCyr-Light', Arial, Helvetica, sans-serif;
      font-weight: 300;
      color: #333;

      &:disabled {
        background-color: hsl(0, 0%, 95%);
        color: $primary-grey2;
      }

      .label {
        background-color: $primary-grey1;
      }
    }

    &:focus {
      border-color: var(--primary-color);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    &_multiple {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not([focus]) {
        border-color: var(--primary-color);
        background-color: var(--primary-color-light);
        padding-right: 14px;
      }
    }

    // input with icon styles
    &__outer {
      position: relative;

      .icon {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 16px;
        height: 16px;

        &-email {
          background: url(../../../assets/images/icons/email.svg) no-repeat center / contain;
        }

        &-person {
          background: url(../../../assets/images/icons/person.svg) no-repeat center / contain;
        }

        &-password {
          background: url(../../../assets/images/icons/password.svg) no-repeat center / contain;
        }
      }
    }

    // date input styles
    &__date {
      display: flex;

      .input {
        padding-right: 14px;

        &:first-child {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right: none;
          width: 55px;
        }

        &:nth-child(2) {
          border-radius: 0;
          width: 55px;
        }

        &:last-child {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-left: none;
          width: 66px;
        }
      }
    }
  }

  &_full {
    .label .label__name {
      width: 100%;
      margin: 0;
      background: $primary-color1;
      padding-left: 8px;
      padding-right: 8px;
    }

    .input {
      height: 32px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &__field-value {
        margin-left: 5px;
      }
    }

    &.success {
      .label .label__name {
        background-color: $success-color;
      }

      .input {
        border-color: $success-color;
        color: $primary-black;
      }
    }

    &.error {
      .label .label__name {
        background-color: $error-color;
      }

      .input {
        color: $primary-black;
        border-color: $error-color;
        background-color: #ffa8a8;
      }
    }
  }

  &_centered {
    align-items: center;

    .label {
      align-items: center;

      span {
        padding: 0 12px;
        margin: 0;
        background-color: var(--primary-color);
      }
    }

    .input {
      text-align: center;
      padding: 0;
      background-color: #fff;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &_standart {
    .label__name {
      background: none;
      padding: 0;
      margin: 0;
      margin-bottom: 12px;
      font-size: 14px;
      color: $primary-black;
    }

    .input {
      color: var(--primary-color);

      &__field-name {
        color: $primary-black;
      }

      &__field-value {
        color: var(--primary-color);
        margin-left: 5px;
      }
    }

    &.success {
      .input {
        color: $primary-black;
        border-color: $success-color;
        background-color: $success-color1;
      }
    }

    &.error {
      .input {
        color: $primary-black;
        border-color: $error-color;
        background-color: #ffa8a8;
      }
    }
  }

  // react-select custom styles
  .select {
    height: 40px;

    &__placeholder,
    &__single-value {
      font-size: 14px;
      line-height: 17px;
      color: $primary-grey2;
      font-weight: 300;
      [data-theme='dark'] & {
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        color: var(--black)
      }
    }

    &__single-value {
      color: $primary-black;
    }

    &__menu {
      border: 1px solid $primary-grey1;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      margin-top: 4px;

      &-list {
        &::-webkit-scrollbar {
          width: 14px;
        }

        &::-webkit-scrollbar-track {
          background: #eff4fa;
        }

        &::-webkit-scrollbar-thumb {
          border: 4px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: #a2b5cf;
        }
      }
    }

    &__option {
      height: 31px;
      font-size: 12px;
      line-height: 14px;
      [data-theme='dark'] & {
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        color: var(--black)
      }
      color: #585a67;
      display: flex;

      &--is-selected,
      &--is-focused {
        background-color: #eff3f8;
        color: #151515;
      }

      // disable hover on touch devices
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: #eff3f8;
          color: #151515;
        }
      }
    }

    &__control {
      height: 40px;
      border-color: $primary-grey1;
      border-radius: 6px;
      color: $primary-grey2;

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 15px;
        height: 5px;
        width: 10px;
        background: url(../../../assets/images/icons/select-arrow.svg) no-repeat center / cover;
        transition: 0.25s;
      }

      &--menu-is-open {
        &:after {
          transform: rotate(180deg);
        }
      }

      &--is-focused {
        border: 5px solid var(--primary-color);
        box-shadow: 0px 0px 6px rgba(66, 101, 207 , 0.25);
      }
    }

    &__single-value {
      display: flex;
      align-items: center;
    }

    &.countryCode {
      .select {
        &__menu {
          min-width: 240px;
        }

        &__option {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.radio-group {
  width: 100%;
  max-width: 570px;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-bottom: 72px;

  @media (max-width: 540px) {
    flex-direction: column;
  }

  .radio {
    @media (max-width: 540px) {
      max-width: none;
      margin-bottom: 16px;
    }
  }
}

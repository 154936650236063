.content_auth {
  .logo-icon {
    width: 90px;
    height: 90px;
    background: url(../../../assets/images/icons/dyop-logo.svg) no-repeat center / cover;
  }

  .content__heading-container {
    margin-bottom: 28px;
  }

  .content__inner {
    background-image: url(../../../assets/images/background1.svg), url(../../../assets/images/background2.svg);
    background-size: 318px 332px, 294px 332px;
    background-repeat: no-repeat;
    background-position: left 50px bottom 0, right 50px bottom 0;

    @media (max-width: 780px) {
      background-image: url(../../../assets/images/background2.svg);
      background-position: right 40px bottom 0;
    }
  }

  &.login {
    .content__inner {
      padding-top: 4%;
    }

    .content__heading-container {
      margin-bottom: 12px;
    }
  }
}

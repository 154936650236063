@import '../../../assets/variables.scss';

.search-field {
  position: relative;

  &__input {
    width: 100%;
    height: 40px;
    padding: 11px 11px 9px 40px;
    background: url(../../../assets/images/icons/searh-icon.svg) no-repeat left 12px top 12px / 16px;
    box-sizing: border-box;
    border: 1px solid $primary-grey1;
    border-radius: 6px;
    outline: none;

    &:disabled {
      background-color: #ebebe4;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      color: $primary-grey2;
      font-weight: 200;
    }
  }

  &::after {
    display: block;
    content: '';
    width: calc(100% + 3px);
    height: 24px;
    position: absolute;
    bottom: -24px;
    left: -1px;
    background-color: #fff;
    z-index: 9;
  }
}

@import '../../../assets/variables.scss';

.patient-info {
  padding: 20px;
  border: 1px solid $primary-grey1;
  border-radius: 6px;
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 24px;

  .page-heading {
    margin-bottom: 4px;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1066px) {
      flex-direction: row-reverse;
    }

    @media (max-width: 735px) {
      flex-direction: row;
    }

    @media (max-width: 540px) {
      flex-direction: row-reverse;
    }
  }

  &__name {
    max-width: 472px;
    flex: 1 1 305px;
    margin-top: 12px;

    @media (max-width: 735px) {
      flex-basis: 364px;
      max-width: 498px;
    }

    @media (max-width: 600px) {
      flex-basis: 305px;
    }
  }

  &__birthdate {
    max-width: 156px;
    flex: 1 1 fit-content;
    margin-left: 8px;
    margin-top: 12px;
  }

  &__phone {
    max-width: 180px;
    flex: 1 1 135px;
    margin-top: 12px;

    @media (max-width: 1066px) {
      max-width: 305px;
    }

    @media (max-width: 735px) {
      max-width: 180px;
    }

    @media (max-width: 540px) {
      max-width: 305px;
    }
  }

  &__email {
    max-width: 310px;
    flex: 1 1 270px;
    margin-left: 8px;
    margin-top: 12px;

    @media (max-width: 1066px) {
      margin-left: 0;
      max-width: 472px;
    }

    @media (max-width: 735px) {
      margin-left: 8px;
      max-width: 465px;
    }

    @media (max-width: 540px) {
      margin-left: 0;
      max-width: 465px;
    }
  }
}

@import '../../../assets/variables.scss';

.distance-result {
  display: flex;
  width: 100%;
  max-width: 540px;
  min-width: 345px;
  border: 1px solid $primary-grey1;
  border-radius: 6px;
  padding: 30px;
  background-color: #fff;
  box-sizing: border-box;
  flex: 1 1 20em;
  margin-left: 20px;
  margin-right: 15px;

  &.self {
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;

    .distance-result__inner {
      width: 100%;
    }

    .distance-result__content {
      margin: 0;

      @media (max-width: 1100px) {
        height: 268px;
      }
    }
  }

  @media (max-width: 1100px) {
    &:not(.self) {
      margin: 0;
      flex-basis: 23em;
      border: none;
      min-width: 100vw;
      justify-content: center;
      padding: 48px 15px 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__content {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 36px;
    margin-bottom: 135px;
    padding-left: 160px;
    height: 268px;

    @media (max-width: 1100px) {
      max-width: 478px;
      box-sizing: border-box;
      height: 332px;
      margin-bottom: 48px;
    }

    &-right {
      display: flex;
      flex-direction: column;
      height: 151px;
      justify-content: space-between;
      align-items: flex-end;
      align-self: flex-end;
      width: 100%;

      &_big {
        height: 174px;
      }
    }

    .icon-monitor {
      background: url(../../../assets/images/monitor.svg) no-repeat center / contain;
      width: 160px;
      height: 268px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .icon-ruler {
      background: url(../../../assets/images/ruler.svg) no-repeat center / 100%;
      max-width: 320px;
      width: 100%;
      height: 34px;
    }

    .icon-footsteps {
      background: url(../../../assets/images/footsteps.svg) no-repeat center / 100%;
      max-width: 320px;
      width: 100%;
      height: 80px;
    }
  }

  &__text {
    max-width: 290px;
    width: 92%;
    height: 40px;
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    color: var(--primary-color);
    background-color: #fff;
    position: relative;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding-top: 9px;
    box-sizing: border-box;

    @media (max-width: 1100px) {
      max-width: none;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@import '../../../../assets/variables.scss';

.content__inner {
  margin-top: 0;
}

.qr-connect-dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  padding: 0 15px;
  box-sizing: border-box;
  padding-bottom: 70px;

  .qr-connect-dark-block{
    text-align: center;
  }

  .light18x27 {
    text-align: center;
  }

  .connect__text {
    margin-top: 20px;
  }
  &__inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 65px;
    border-radius: 6px;
    border: 1px solid $primary-grey1;

    &.top {
      margin-top: 42px;
    }

    // qr code style
    .svg-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      height: 400px;
      margin-top: 32px;

      @media (max-width: 1000px) {
        width: 320px;
        height: 320px;
      }

      &__inner {
        svg {
          width: 320px;
          height: 320px;
          @media (max-width: 1000px) {
            width: 250px;
            height: 250px;
          }
        }
      }

      &::before,
      &::after,
      &__inner::before,
      &__inner::after {
        position: absolute;
        content: ' ';
        width: 40px;
        height: 40px;
        border-color: $primary-black;
        border-style: solid;
      }

      &::before {
        top: 0;
        left: 0;
        border-width: 1px 0 0 1px;
      }

      &::after {
        top: 0;
        right: 0;
        border-width: 1px 1px 0 0;
      }

      &__inner::before {
        bottom: 0;
        right: 0;
        border-width: 0 1px 1px 0;
      }

      &__inner::after {
        bottom: 0;
        left: 0;
        border-width: 0 0 1px 1px;
      }
    }
  }
}

@import '../../../assets/variables.scss';

.status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &__indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;

    &.error {
      background-color: $error-color;
    }

    &.success {
      background-color: #57bc33;
    }
  }

  &__text {
    font-size: 14px;
    white-space: nowrap;
    margin-right: 27px;

    &.error {
      color: $error-color;
    }

    &.success {
      color: #57bc33;
    }
  }
}
